<template>
    <section class="oportunidades-main">
        <navbar-admin icon="icon-star" />
        <tabs :tabs="tabsOportunidades" />
        <router-view />
    </section>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    data(){
        return{
            titulo:'',
            tabsOportunidades: [
                {
                    titulo:'Vigentes',
                    ruta:"oportunidades.listado.vigentes",
                },
                {
                    titulo:'Ganadas',
                    ruta:"oportunidades.listado.ganadas",
                },
                {
                    titulo:'Perdidas',
                    ruta:"oportunidades.listado.perdidas",
                },
            ],
        }
    },
    watch:{
        "$route.name"(route){
            this.replaceBreadcumb({ position: 1, breadcumb: route})
        }
    },
    created(){
        this.getBreadcumbs(['oportunidades.main']);
        this.replaceBreadcumb({position: 1, breadcumb: this.$route.name })
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
            replaceBreadcumb: 'navigation/breadcumbs/replaceBreadcumb'
        })
    }
}
</script>